@import url("https://p.typekit.net/p.css?s=1&k=szf0cln&ht=tk&f=10294.10296.25327&a=69061329&app=typekit&e=css");
body {
  background-color: black;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "requiem";
  overflow-x: hidden;
}

.content-box {
  flex-direction: column;
  overflow-x: hidden;
}

@media screen and (min-width: 600px) {
  .content-box {
    flex-direction: row;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a, a:visited {
  color: "black";
  text-decoration: none; 
}

a:hover {
  text-decoration: underline;
}

.mobile-link:hover {
  text-decoration: none;
}
main {
  background-color: black;
}


.text-white {
  color: transparent;

  font-family: "prohibition";
  font-weight: 400;
  font-size: 15vh;
  -webkit-text-fill-color: #fff;

}

.transparent-text-white-outline {
  color: transparent;
  font-family: "prohibition";
  font-weight: 400;
  font-size: 15vh;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #fff;
}



.container {
  display: flex;
  width: 100%;
  height: 78vh;
}

.box {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
}

.box1 {
  background-color: lightblue;
  flex: 60%;
}

.box2 {
  background-color: lightgreen;
  flex: 40%;
}

.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}
